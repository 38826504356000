import {
  useInfiniteQuery,
  useQueryClient,
  type InfiniteData,
  type UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import type { BasketItem } from '@repo/domain-kit/payment-links';
import { useCallback } from 'react';
import type ProductModel from 'qonto/models/product';

export const DEFAULT_ITEMS_PER_PAGE = 50;

interface FetchProductsArgs {
  page?: number;
  perPage?: number;
  sortBy?: string;
}

export interface PaymentLinkItemsResponse {
  products: BasketItem[];
  meta: {
    total_count: number;
    next_page: number | null;
  };
}

type UseFetchInfinitePaymentLinkItemsResult = UseInfiniteQueryResult<
  InfiniteData<PaymentLinkItemsResponse>
> & {
  updateProductInCache: (updatedProduct: BasketItem) => void;
  addProductToCache: (newProduct: ProductModel) => void;
};

export const convertProductToBasketItem = (product: ProductModel): BasketItem => {
  return {
    id: product.id,
    type: product.type,
    title: product.title,
    description: product.description,
    unitPrice: {
      value: product.unitPrice.value,
      currency: product.unitPrice.currency,
    },
    vatRate: product.vatRate,
    quantity: 1,
    measureUnit: product.unit ?? 'unit',
  };
};

export function useFetchInfinitePaymentLinkItems({
  perPage = DEFAULT_ITEMS_PER_PAGE,
  sortBy = 'created_at:desc',
}: Omit<FetchProductsArgs, 'page'>): UseFetchInfinitePaymentLinkItemsResult {
  const queryClient = useQueryClient();
  const store = useEmberService('store');

  const fetchProducts = async (page: number): Promise<PaymentLinkItemsResponse> => {
    const products = await store.query<'product'>('product', {
      page: { number: page, size: perPage },
      sort_by: sortBy,
    });

    return {
      products: products.toArray().map(convertProductToBasketItem),
      // @ts-expect-error -- No types for ember-data meta
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- No types for ember-data meta
      meta: products.meta,
    };
  };

  const fetchItemsQuery = useInfiniteQuery({
    queryKey: ['infinite-products', sortBy, perPage],
    queryFn: ({ pageParam }) => fetchProducts(pageParam),
    getNextPageParam: lastPage => lastPage.meta.next_page,
    initialPageParam: 1,
    refetchOnWindowFocus: false,
  });

  const updateProductInCache = useCallback(
    (updatedProduct: BasketItem): void => {
      queryClient.setQueryData<InfiniteData<PaymentLinkItemsResponse>>(
        ['infinite-products', sortBy, DEFAULT_ITEMS_PER_PAGE],
        oldData => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            pages: oldData.pages.map(page => ({
              ...page,
              products: page.products.map(product =>
                product.id === updatedProduct.id ? updatedProduct : product
              ),
            })),
          };
        }
      );
    },
    [queryClient, sortBy]
  );

  const addProductToCache = useCallback(
    (newProduct: ProductModel): void => {
      queryClient.setQueryData<InfiniteData<PaymentLinkItemsResponse>>(
        ['infinite-products', 'created_at:desc', DEFAULT_ITEMS_PER_PAGE],
        oldData => {
          if (!oldData) return oldData;
          return {
            ...oldData,
            pages: oldData.pages.map((page, index) => {
              if (index === 0) {
                return {
                  ...page,
                  products: [convertProductToBasketItem(newProduct), ...page.products],
                };
              }
              return page;
            }),
          };
        }
      );
    },
    [queryClient]
  );

  return {
    ...fetchItemsQuery,
    updateProductInCache,
    addProductToCache,
  };
}
