import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { type FlowHeaderProps } from '@repo/domain-kit/accounts-receivable';
import { useQuery } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useId } from 'react';
import { Disclaimer, Select, SelectOption, Spinner } from '@repo/design-system-kit';
import { PaymentMethodSelectorToggle } from '@repo/domain-kit/invoices';
import { BankAccountAvatar } from 'qonto/react/components/bank-account-avatar';
import type PaymentLinkMethodModel from 'qonto/models/payment-link-method';
import { PaymentLinkFormStep } from './payment-link-form-step';
import styles from './payment-methods.strict-module.css';

type Method = PaymentLinkMethodModel['method'];

interface PaymentMethodsProps {
  breadcrumb: FlowHeaderProps['breadcrumb'];
  selectedMethods: Method[];
  onSelectMethod: (method: Method) => void;
  onRemoveMethod: (method: Method) => void;
  onClose: () => void;
  onContinue: () => void;
}

export function PaymentMethods({
  breadcrumb,
  selectedMethods,
  onSelectMethod,
  onRemoveMethod,
  onClose,
  onContinue,
}: PaymentMethodsProps): ReactNode {
  const { formatMessage } = useIntl();
  const store = useEmberService('store');
  const query = useQuery<PaymentLinkMethodModel[]>({
    queryKey: ['payment-link-methods'],
    queryFn: async () => {
      const methods = (
        await store.query('payment-link-method', {
          'filter[amount]': 100,
          'filter[currency]': 'EUR',
          'filter[resource_type]': 'Basket',
        })
      ).toArray();
      if (selectedMethods.length === 0) {
        methods.forEach(({ method }) => {
          onSelectMethod(method);
        });
      }
      return methods;
    },
  });
  const bankAccountLabelId = useId();
  const mollie = useEmberService('mollie');
  const segment = useEmberService('segment');
  const bankAccount = mollie.bankAccountId
    ? store.peekRecord('bank-account', mollie.bankAccountId)
    : null;

  if (query.isPending) {
    return (
      <div className={styles.contentSpinner}>
        <Spinner color="primary-a" size="large" />
      </div>
    );
  }

  const togglePaymentMethod = (
    method: PaymentLinkMethodModel['method'],
    isSelected: boolean
  ): void => {
    if (isSelected) {
      onSelectMethod(method);
    } else {
      onRemoveMethod(method);
    }
  };

  const lastSelectedMethod: Method | null =
    (selectedMethods.length === 1 && selectedMethods[0]) || null;

  return (
    <PaymentLinkFormStep
      footerProps={{
        buttonLabel: formatMessage({ id: 'btn.continue' }),
        onClickNext: () => {
          if (selectedMethods.length > 0) {
            onContinue();
          }
        },
      }}
      headerProps={{
        breadcrumb,
        onClose,
        title: formatMessage({ id: 'payment-link.create.title' }),
      }}
      onClose={onClose}
      // TODO: pass correct values
      previewProps={{
        amount: { value: '0.00', currency: 'EUR' },
        items: [],
        methods: [],
        organizationName: '',
      }}
    >
      <h2 className="title-3 mb-16" data-test-account-title id={bankAccountLabelId}>
        {formatMessage({ id: 'receivable-invoices.payment-link-creation.account-to-credit' })}
      </h2>
      <Select
        aria-labelledby={bankAccountLabelId}
        className="mb-32"
        data-test-account-select
        isReadOnly
        label=""
        placeholder=""
        selectedKey="1"
      >
        {bankAccount ? (
          <SelectOption id="1">
            <div className={styles.bankAccountOption}>
              <BankAccountAvatar
                bankAccount={bankAccount}
                className={styles.bankAccountAvatar}
                size="small"
              />
              {bankAccount.safeName}
            </div>
          </SelectOption>
        ) : null}
      </Select>

      <h2 className="title-3 mb-16" data-test-payment-methods-title>
        {formatMessage({ id: 'payment-link.create.step-2.payment-methods.title' })}
      </h2>
      <p className="body-2 mb-16" data-test-payment-methods-subtitle>
        {formatMessage(
          { id: 'payment-link.create.step-2.payment-methods.subtitle.text' },
          {
            faqArticle: (
              <a
                className="body-link"
                href={formatMessage({
                  id: 'payment-link.create.step-2.payment-methods.subtitle.url',
                })}
                key="faq-link"
                onClick={() => {
                  segment.track('payment-links_faq_clicked');
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                {formatMessage({ id: 'payment-link.create.step-2.payment-methods.subtitle.link' })}
              </a>
            ),
          }
        )}
      </p>

      <ul className={styles.paymentMethods}>
        {query.data
          ? query.data.map(({ method }: PaymentLinkMethodModel) => (
              <li key={method}>
                <PaymentMethodSelectorToggle
                  checked={selectedMethods.includes(method)}
                  isReadOnly={method === lastSelectedMethod}
                  method={method}
                  onChange={isSelected => {
                    togglePaymentMethod(method, isSelected);
                  }}
                />
                {method === lastSelectedMethod ? (
                  <Disclaimer.Inline className={styles.lastItemDisclaimer}>
                    {formatMessage({
                      id: 'payment-link.create.step-2.payment-methods.disclaimer.required',
                    })}
                  </Disclaimer.Inline>
                ) : null}
              </li>
            ))
          : null}
      </ul>
    </PaymentLinkFormStep>
  );
}
