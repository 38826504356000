import { useCallback, useMemo, type ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks/use-ember-service';
import {
  Button,
  Disclaimer,
  Selector,
  SkeletonLoaderComponent,
  Spinner,
  useToast,
} from '@repo/design-system-kit';
import { type FlowHeaderProps } from '@repo/domain-kit/accounts-receivable';
import {
  calculateBasketTotals,
  SelectableItem,
  type BasketItem,
  type ItemFormErrors,
} from '@repo/domain-kit/payment-links';
import { EmptyStatesInform } from '@repo/domain-kit/pricing';
import { useInfiniteScroll } from 'qonto/react/hooks/ui/use-infinite-scroll';
import { useFetchInfinitePaymentLinkItems } from 'qonto/react/hooks/use-fetch-infinite-payment-link-items';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager.ts';
import { validateBasketItemList } from 'qonto/react/payment-links/utils/validate-basket-items';
import { PaymentLinkFormStep } from './payment-link-form-step';
import styles from './products-and-services.strict-module.css';

interface ProductsAndServicesProps {
  breadcrumb: FlowHeaderProps['breadcrumb'];
  onClose: () => void;
  selectedItems: BasketItem[];
  onSelectItem: (product: BasketItem) => void;
  onRemoveItem: (product: BasketItem) => void;
}

interface Error {
  id: string;
  errors: ItemFormErrors;
}

export function ProductsAndServices({
  breadcrumb,
  onClose,
  selectedItems,
  onSelectItem,
  onRemoveItem,
}: ProductsAndServicesProps): ReactNode {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { organization } = useOrganizationManager();
  const modals = useEmberService('modals');
  const store = useEmberService('store');
  const { showToast } = useToast();

  const {
    data,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    updateProductInCache,
    addProductToCache,
  } = useFetchInfinitePaymentLinkItems({ sortBy: 'created_at:desc' });

  const loadMoreRef = useInfiniteScroll(isLoading, isFetchingNextPage, hasNextPage, fetchNextPage);

  const { totalIncludingVat } = useMemo(
    () => calculateBasketTotals(selectedItems),
    [selectedItems]
  );
  const basketAmount = {
    value: String(totalIncludingVat),
    currency: selectedItems[0]?.unitPrice?.currency || 'EUR',
  };
  const [errors, setErrors] = useState<Error[]>([]);

  const updateProduct = useCallback(
    (updatedProduct: BasketItem): void => {
      updateProductInCache(updatedProduct);

      // Update selectedItems if the product is selected
      const selectedItem = selectedItems.find(item => item.id === updatedProduct.id);
      if (selectedItem) {
        onRemoveItem(selectedItem);
        onSelectItem(updatedProduct);
      }
    },
    [updateProductInCache, onRemoveItem, onSelectItem, selectedItems]
  );

  const firstSelectedCurrency = selectedItems[0]?.unitPrice?.currency;
  const products = data?.pages.flatMap(page => page.products) ?? [];

  const hasMultipleProductCurrencies =
    new Set(products.map(product => product.unitPrice.currency)).size > 1;

  const handleAddNewItem = useCallback(() => {
    const product = store.createRecord('product');

    modals.open('products/creation-form-modal', {
      isFullScreenModal: true,
      focusTrapOptions: {
        clickOutsideDeactivates: false,
        allowOutsideClick: false,
        escapeDeactivates: false,
      },
      onSaveSuccess: () => {
        showToast({
          text: formatMessage({ id: 'products.creation-form.toasts.success.product-added' }),
          type: 'success',
        });
        addProductToCache(product);
      },
      product,
    });
  }, [formatMessage, modals, addProductToCache, store, showToast]);

  const handleClickNext = useCallback(() => {
    setErrors([]);
    if (selectedItems.length === 0) {
      showToast({
        text: formatMessage({ id: 'payment-link.create.step-1.item.selection.error-toast' }),
        type: 'error',
      });
      return;
    }

    const validationErrors = validateBasketItemList(selectedItems);
    setErrors(validationErrors);

    if (validationErrors.length === 0) {
      // TODO: navigate to next step
    }
  }, [formatMessage, selectedItems, showToast]);

  return (
    <PaymentLinkFormStep
      footerProps={{
        isHidden: products.length === 0,
        buttonLabel: formatMessage({ id: 'btn.continue' }),
        onClickNext: () => {
          handleClickNext();
        },
      }}
      headerProps={{
        breadcrumb,
        onClose,
        title: formatMessage({ id: 'payment-link.create.title' }),
      }}
      onClose={onClose}
      previewProps={{
        amount: basketAmount,
        items: selectedItems,
        methods: [],
        organizationName: organization.name,
      }}
    >
      {products.length === 0 ? (
        <>
          {isLoading ? (
            <div className={styles.loadingWrapper} data-test-payment-links-products-spinner>
              <Spinner color="primary-a" size="large" />
            </div>
          ) : (
            <div className={styles.emptyWrapper}>
              <div className={styles.emptyContent}>
                <EmptyStatesInform
                  lottieSrc="/lotties/no-items.json"
                  subtitle={formatMessage({ id: 'payment-link.create.step-1.empty.description' })}
                  title={formatMessage({ id: 'payment-link.create.step-1.empty.title' })}
                />
                <Button data-test-add-payment-link-item onPress={handleAddNewItem}>
                  {formatMessage({ id: 'payment-link.create.step-1.empty.cta' })}
                </Button>
              </div>
            </div>
          )}
        </>
      ) : (
        <form>
          <fieldset className={styles.fieldset}>
            <legend className="title-3 mb-16">
              <FormattedMessage id="payment-link.create.step-1.title" />
            </legend>
            <p className="body-2 mb-16">
              <FormattedMessage id="payment-link.create.step-1.subtitle" />
            </p>
            <Selector
              className="mb-16"
              data-test-add-item
              onPress={handleAddNewItem}
              subtitle={formatMessage({
                id: 'payment-link.create.step-1.item.create.subtilte',
              })}
              title={formatMessage({ id: 'payment-link.create.step-1.item.create.tilte' })}
              variant="add"
            />
            {hasMultipleProductCurrencies && selectedItems.length > 0 ? (
              <Disclaimer.Block className={styles.disclaimer} data-test-disclaimer level="info">
                <FormattedMessage id="payment-link.create.step-1.item.selection.disclaimer" />
              </Disclaimer.Block>
            ) : null}

            {isFetching ? (
              <div className={styles.skeletonContainer} data-test-payment-links-products-loader>
                {Array.from({ length: 5 }).map((_, index) => (
                  <SkeletonLoaderComponent
                    height="80px"
                    // eslint-disable-next-line react/no-array-index-key -- array is immutable
                    key={`skeleton-${index}`}
                    type="block"
                    width="100%"
                  />
                ))}
              </div>
            ) : (
              <div className={styles.products}>
                {products.map((product, index) => (
                  <div data-test-product-item={index} key={product.id}>
                    <SelectableItem
                      disabled={
                        firstSelectedCurrency !== undefined &&
                        product.unitPrice.currency !== firstSelectedCurrency
                      }
                      errors={errors.find(error => error.id === product.id)?.errors ?? []}
                      isSelected={selectedItems.find(p => p.id === product.id) !== undefined}
                      item={product}
                      onSelect={() => {
                        if (selectedItems.find(p => p.id === product.id)) {
                          onRemoveItem(product);
                        } else {
                          onSelectItem(product);
                        }
                      }}
                      onUpdate={updateProduct}
                      vatRates={organization.vatRatesCategories}
                    />
                  </div>
                ))}
              </div>
            )}
          </fieldset>
        </form>
      )}
      {hasNextPage ? <div data-test-infinite-scroll-trigger ref={loadMoreRef} /> : null}
    </PaymentLinkFormStep>
  );
}
