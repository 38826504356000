import { type ReactNode, useEffect } from 'react';
import { useEmberService, useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import cx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { MarketingButton } from 'qonto/react/components/freemium-upgrade/marketing-button';
import { MarketingCard } from 'qonto/react/components/freemium-upgrade/marketing-card';
import { MarketingFeaturesTable } from 'qonto/react/components/freemium-upgrade/marketing-features-table';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { MarketingCarousel } from 'qonto/react/components/freemium-upgrade/marketing-carousel';
import { MarketingTestimonial } from 'qonto/react/components/freemium-upgrade/marketing-testimonial';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './freemium-upgrade.strict-module.css';

const TRUST_PILOT_SCORE = 4.8;

export function FreemiumUpgrade(): ReactNode {
  const intl = useIntl();
  const zendeskLocalization = useEmberService('zendesk-localization');
  const upsellManager = useEmberService('upsell-manager');
  const { organization } = useOrganizationManager();
  const router = useRouter();

  useEffect(() => {
    void upsellManager.getSelectedBillerPricePlan();
  }, [upsellManager]);

  function handleMarketingButtonClick(): void {
    if (upsellManager.shouldResumeFreemiumUpgrade) {
      void router.push(`/organizations/${organization.slug}/freemium-upgrade/modal`);
    } else {
      upsellManager.upsell();
    }
  }

  return (
    <main className={styles.freemiumUpgrade}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.pageSection}>
            <h1 className="title-1 mb-16" data-test-freemium-upgrade-title>
              <FormattedMessage id="qonto-invoicing.upsell-page.level1.title" />
            </h1>
            <h2 className={cx(styles.pageSubtitle, 'title-4')} data-test-freemium-upgrade-subtitle>
              <FormattedMessage id="qonto-invoicing.upsell-page.level1.subtitle" />
            </h2>
          </div>

          <section className={styles.marketingCarouselWrapper}>
            <MarketingCarousel />
          </section>

          <h1 className={cx(styles.pageSection, 'title-1', 'mb-32')}>
            <FormattedMessage id="qonto-invoicing.upsell-page.level2.title" />
          </h1>

          <section
            className={cx(styles.pageSection, styles.marketingCards)}
            data-test-marketing-cards-section
          >
            <MarketingCard
              lottieProps={{
                relativePath: '/freemium-upgrade/upsell-cash-flow.json',
                locale: intl.locale,
              }}
              subtitle={intl.formatMessage({
                id: 'qonto-invoicing.upsell-page.level2.secondary-feature1.subtitle',
              })}
              title={intl.formatMessage({
                id: 'qonto-invoicing.upsell-page.level2.secondary-feature1.title',
              })}
            />

            <MarketingCard
              lottieProps={{
                relativePath: '/freemium-upgrade/more-integrations.json',
                locale: intl.locale,
              }}
              subtitle={intl.formatMessage({
                id: 'qonto-invoicing.upsell-page.level2.secondary-feature2.subtitle',
              })}
              title={intl.formatMessage({
                id: 'qonto-invoicing.upsell-page.level2.secondary-feature2.title',
              })}
              type="secondary"
            />

            <MarketingCard
              lottieProps={{
                relativePath: '/freemium-upgrade/upsell-financing.json',
                locale: intl.locale,
              }}
              subtitle={
                <FormattedMessage
                  id="qonto-invoicing.upsell-page.level2.secondary-feature3.subtitle"
                  values={{
                    learnMoreLink: (
                      <>
                        <br />
                        <a
                          className="body-link"
                          data-test-freemium-upgrade-feature
                          href={zendeskLocalization.getLocalizedArticle(9015327)}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <FormattedMessage id="qonto-invoicing.upsell-page.level2.secondary-feature3.faqLink" />
                        </a>
                      </>
                    ),
                  }}
                />
              }
              title={intl.formatMessage({
                id: 'qonto-invoicing.upsell-page.level2.secondary-feature3.title',
              })}
              type="secondary"
            />
          </section>

          <section className={cx(styles.pageSection, styles.upgradeFeatures)}>
            <div className={styles.upgradeFeaturesText}>
              <h1 className="title-1 mb-16">
                <FormattedMessage id="qonto-invoicing.upsell-page.level3.title" />
              </h1>
              <h2 className="title-4">
                <FormattedMessage id="qonto-invoicing.upsell-page.level3.subtitle" />
              </h2>
            </div>
            <MarketingFeaturesTable />
          </section>

          <section className={cx(styles.pageSection, styles.testimonials)}>
            <h1 className="title-1 mb-32">
              <FormattedMessage id="qonto-invoicing.upsell-page.level4.title" />
            </h1>
            <div className={styles.testimonialsWrapper}>
              <MarketingTestimonial
                job={intl.formatMessage({
                  id: 'qonto-invoicing.upsell-page.level4.customer1.job',
                })}
                name={intl.formatMessage({
                  id: 'qonto-invoicing.upsell-page.level4.customer1.name',
                })}
                picturePath="/images/a-gautier-bw-testimonial.png"
                quote={intl.formatMessage({
                  id: 'qonto-invoicing.upsell-page.level4.customer1.quote',
                })}
              />

              <MarketingTestimonial
                job={intl.formatMessage({
                  id: 'qonto-invoicing.upsell-page.level4.customer2.job',
                })}
                name={intl.formatMessage({
                  id: 'qonto-invoicing.upsell-page.level4.customer2.name',
                })}
                picturePath="/images/m-comi-bw-testimonial.png"
                quote={intl.formatMessage({
                  id: 'qonto-invoicing.upsell-page.level4.customer2.quote',
                })}
              />

              <MarketingTestimonial
                job={intl.formatMessage({
                  id: 'qonto-invoicing.upsell-page.level4.customer3.job',
                })}
                name={intl.formatMessage({
                  id: 'qonto-invoicing.upsell-page.level4.customer3.name',
                })}
                picturePath="/images/a-rossin-bw-testimonial.png"
                quote={intl.formatMessage({
                  id: 'qonto-invoicing.upsell-page.level4.customer3.quote',
                })}
              />
            </div>

            <p className="body-1 mb-8">
              <FormattedMessage id="qonto-invoicing.upsell-page.level4.trustpilot" />
            </p>

            <div className={styles.trustpiolotScore}>
              <span className="title-3">{TRUST_PILOT_SCORE}</span>
              <StaticThemedAsset alt="" assetPath="/illustrations/trustpilot.svg" />
            </div>
          </section>
        </div>
      </div>

      <div className={styles.marketingButtonWrapper}>
        <MarketingButton onPress={handleMarketingButtonClick} />
      </div>
    </main>
  );
}
