import type { ReactNode } from 'react';
import { Button, LottiePlayer, useToast } from '@repo/design-system-kit';
import { useIntl, FormattedMessage } from 'react-intl';
import styles from './success.strict-module.css';

export function Success({ onClose }: { onClose: () => void }): ReactNode {
  const { formatMessage } = useIntl();
  const { showToast } = useToast();

  const lottie = '/lotties/success.json';
  return (
    <main className={styles.wrapper}>
      <section className={styles.content}>
        <figure className={styles.illustration}>
          <LottiePlayer
            className={styles.lottieWrapper}
            data-test-lottie={lottie}
            loop={false}
            path={lottie}
          />
        </figure>
        <article className={styles.success}>
          <h1 className="title-2" data-test-success-title>
            <FormattedMessage id="payment-link.create.success.title" />
          </h1>
          <p className="body-1" data-test-success-subtitle>
            <FormattedMessage id="payment-link.create.success.subtitle" />
          </p>
        </article>
        <div className={styles.actions}>
          <Button
            aria-label={formatMessage({ id: 'payment-link.create.success.cta-primary' })}
            data-test-copy-payment-link-url
            onPress={() => {
              // TODO: implement copy
              showToast({
                text: formatMessage({ id: 'payment-link.create.success.success-toast' }),
              });
            }}
            size="medium"
            variant="primary"
          >
            <FormattedMessage id="payment-link.create.success.cta-primary" />
          </Button>
          <Button
            aria-label={formatMessage({ id: 'payment-link.create.success.cta-secondary' })}
            data-test-close-step-button
            onPress={onClose}
            size="medium"
            variant="tertiary"
          >
            <FormattedMessage id="payment-link.create.success.cta-secondary" />
          </Button>
        </div>
      </section>
    </main>
  );
}
