import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import { PriceBreakdown } from '@repo/domain-kit/pricing';
import { Button } from '@repo/design-system-kit';
import styles from './styles.strict-module.css';

interface Amount {
  value: string;
  currency: string;
}

interface ConfirmBillingSummaryProps {
  onConfirm: () => void;
  isCtaDisabled?: boolean;
  isLoading?: boolean;
  confirmText: string;
  billing: string;
  price: string;
  products: { title: string; price: string }[];
  total: string;
  children: ReactElement;
  totalEstimate: {
    type: 'charge' | 'refund';
    next_billing_date: Date;
    subscription_credit_amount: Amount;
    discount_amount: Amount;
    vat_amount: Amount;
    vat_included_amount: Amount;
  };
  includeVat?: boolean;
  includeDiscount?: boolean;
  includeAmount?: boolean;
  testId: string;
  confirmButtonTestId: string;
}

interface Product {
  title: string;
  price: string;
}

export function ConfirmBillingSummary({
  onConfirm,
  isCtaDisabled,
  isLoading,
  confirmText,
  children,
  billing,
  price,
  products,
  total,
  totalEstimate,
  includeVat = true,
  includeDiscount = true,
  includeAmount = true,
  testId,
  confirmButtonTestId,
}: ConfirmBillingSummaryProps): ReactElement {
  const { formatMessage, formatNumber } = useIntl();

  const [productsWithTotal, setProductsWithTotal] = useState<Product[]>([]);

  useEffect(() => {
    const productsArr: Product[] = [...products];
    if (includeAmount && totalEstimate.subscription_credit_amount.value !== '0') {
      productsArr.push({
        title: formatMessage({ id: 'subscription.change.confirmation.credit.heading' }),
        price: formatNumber(Number(totalEstimate.subscription_credit_amount.value), {
          style: 'currency',
          currency: totalEstimate.subscription_credit_amount.currency,
        }),
      });
    }

    if (includeDiscount && totalEstimate.discount_amount.value !== '0') {
      productsArr.push({
        title: formatMessage({ id: 'subscription.change.confirmation.discount' }),
        price: formatNumber(Number(totalEstimate.discount_amount.value), {
          style: 'currency',
          currency: totalEstimate.discount_amount.currency,
        }),
      });
    }
    if (includeVat) {
      productsArr.push({
        title: formatMessage({ id: 'subscription.change.confirmation.vat' }),
        price: formatNumber(Number(totalEstimate.vat_amount.value), {
          style: 'currency',
          currency: totalEstimate.vat_amount.currency,
        }),
      });
    }
    setProductsWithTotal(productsArr);
  }, [
    includeDiscount,
    includeVat,
    includeAmount,
    formatMessage,
    formatNumber,
    products,
    totalEstimate,
  ]);

  return (
    <div className={styles.container} data-test-confirm-billing-summary {...{ [testId]: true }}>
      <h2 className="title-3" data-test-confirm-billing-summary-title>
        {formatMessage({ id: 'subscription.change.confirmation.billing-summary.title' })}
      </h2>
      <PriceBreakdown billing={billing} price={price} products={productsWithTotal} total={total}>
        {children}
        <div className={styles.divider} />
      </PriceBreakdown>
      <Button
        {...{ [confirmButtonTestId]: true }}
        data-test-confirm-billing-summary-confirm-button
        isDisabled={isCtaDisabled || isLoading}
        isLoading={isLoading}
        onPress={onConfirm}
        type="button"
        variant="primary"
      >
        {confirmText}
      </Button>
    </div>
  );
}
